<template>
  <div  class="contant d-flex flex-column align-center  " :class="{ mobile: isMobile }">
    <div class=" d-flex flex-lg-row flex-column align-center " >

<div class="left">
    <img v-if="!isMobile" src="@/assets/logo1.png" height="90" alt="">
    <img v-else src="@/assets/mobile/m_logo1.png" width="80%"  alt="">
    <div class="des mt-7">Your multi-chain and cross-chain crypto wallet, designed to manage fungible tokens (FTs) and a diverse range of non-fungible tokens (NFTs) and real-world assets (RWAs).</div>
    <div class="download mt-8">
      <div class="download d-flex flex-row ">
        <img src="@/assets/d_android.png"   @mouseenter="mousedAndroid()"
      @mouseleave ="leaveAndroid()" @click="downAndroid"  height="50" alt="">
     
      <img  @mouseenter="mousedGoogle()"
      @mouseleave ="leaveGoogle()" src="@/assets/d_googleplay.png"   @click="downGoogle" 
     class="ml-6 testflight" height="50" alt="">
      </div>
      <div class="download mt-lg-4 mt-2">
    
     <img  @mouseenter="mousedAppleStore()"
      @mouseleave ="leaveAppleStore()" src="@/assets/d_appstore.png"   @click="downAppstore" 
     class=" testflight" height="50" alt="">
     <img src="@/assets/d_testflight.png"   @click="downIos"    @mouseenter="mousedIos()"
      @mouseleave ="leaveIos()" class="ml-6 testflight"   height="50" alt="">
    </div>

    

      <img v-if="isShowAndroid && !isMobile" class="android_code" src="@/assets/download_android.png" width="185" alt="">
      <img v-if="isShowIos && !isMobile" class="ios_code" src="@/assets/download_ios.png" width="185" alt="">
      <img v-if="isShowFGoogle && !isMobile" class="google_code" src="@/assets/download_google.png" width="185"  alt="">
      <img v-if="isShowAppleStore && !isMobile" class="apple_code" src="@/assets/download_appstore.png" width="185" alt="">

      
    </div>

   
</div>
<div class="right">
  <img v-if="!isMobile" src="@/assets/bg1.png"  height="600" alt="">
  <img v-else src="@/assets/bg1.png"  height="502" width="100%" alt="">
</div>
</div>
<div class="upwardDes">
  <div class="title-35"> Why Upward Wallet?</div>
  <div class="title-18 mt-8">Upward Wallet is a non-custodial digital wallet specifically designed to integrate seamlessly within the Uptick Network ecosystem with interchain interoperability for both fungible tokens and non-fungible tokens. It supports interaction with multiple blockchain networks and facilitates the transfer of digital assets across them, embodying the ethos of innovation, accessibility, and smooth Web3 integration.</div>
  <img  src="@/assets/image_01.png" class="uptickImage"  alt="">
</div>
<div class="upwardDes mt-5">
  <div class="title-35 mb-14">Highlights</div>
  <div class="hightItem ">
    <div class="item" v-for="(item,index) in hightLightList" :key="index">
      <img  :src="require('@/assets/'+item.image)" class="iconImage" alt="">
      <div class="itemRight ml-7">
          <div class="name">{{item.name}}</div>
          <div class="description mt-1">{{item.description}}</div>
      </div>
    </div>
    

  </div>
</div>

  </div>
 
</template>

<script>

export default {
  name: "Home",
  components: {},
  data: function () {
    return {
      isShowAndroid:false,
      isShowIos:false,
      isShowFGoogle:false,
      isShowAppleStore:false,
      hightLightList:[
        {
          image:'icon_01.png',
          name:'Non-Custodial Security',
          description:'Your assets, your control—full ownership and safety in your hands.'
        },

        {
          image:'icon_02.png',
          name:'Web3 Ecosystem Integration',
          description:'Seamlessly connect with a new world of decentralized applications.'
        },
        {
          image:'icon_03.png',
          name:'Social Account Integration',
          description:'Simplified wallet creation through familiar social login options.'
        },
        {
          image:'icon_04.png',
          name:'Built-in Faucet for New Users',
          description:'Providing free gas to eliminate entry barriers for newcomers in the crypto space.'
        },
        {
          image:'icon_05.png',
          name:'Innovative Incentive & Rewards Mechanism',
          description:'Boost engagement and reward loyalty with dynamic incentives.'
        },
        {
          image:'icon_06.png',
          name:'Comprehensive FT & NFT/RWA Support',
          description:'Supports a broad spectrum of token standards, beyond ERC-20, ERC-721, and ERC-1155.'
        },
        {
          image:'icon_07.png',
          name:'Complete EVM & Cosmos-SDK Chain Support',
          description:'Effortlessly integrates with popular Cosmos and EVM chains, with manual addition options.'
        },
        {
          image:'icon_08.png',
          name:'Multi-Chain & Cross-Chain Interoperability',
          description:'Powered by Uptick Cross-Chain Bridge (UCB) and Inter-Blockchain Communication (IBC).'
        },
        {
          image:'icon_09.png',
          name:'Community Governance & Staking',
          description:'Clear, intuitive tools for active participation in community governance.'
        },
        {
          image:'icon_10.png',
          name:'Omni-Payment Support for Ecosystem Applications',
          description:'Enabling seamless collaboration and integration across ecosystem applications.'
        }
      ]
    };
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },

  async mounted() {
  
   },

   
 
  methods: {
    downGoogle(){
      window.open("https://play.google.com/store/apps/details?id=com.uptick.wallet","_blank");
    },
    downAppstore(){
    
      window.open("https://apps.apple.com/us/app/upward-wallet/id6472655450","_blank");
    },
  
    downAndroid(){
      window.open("https://file.uptick.network/app/Upwardwallet.apk","_blank");
    },
    downIos(){
      window.open('https://testflight.apple.com/join/X5GWHcO1','_blank')
    },
    mousedAndroid(){
        this.isShowAndroid = true
    },
    leaveAndroid(){
        this.isShowAndroid = false
    },
    mousedIos(){
        this.isShowIos = true
    },
    leaveIos(){
        this.isShowIos = false
    },
    mousedGoogle(){
      this.isShowFGoogle = true
    },
    leaveGoogle(){
      this.isShowFGoogle = false
    },
    mousedAppleStore(){
      this.isShowAppleStore =true
    },
    leaveAppleStore(){
      this.isShowAppleStore =false
    }
  
  
  },
};
</script>

<style lang="scss" scoped>
.contant {
  width: 100%;
  height: 100%;
  .left{
    .des{
      width: 555px;
      font-family: Helvetica;
	font-size: 17px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 20px;
	color: #ffffff;
    }
    .download{
      position: relative;
      img{
        cursor: pointer;
      }
      .android_code{
        position: absolute;
        top: -189px;
        left: 20px;
      }
      .ios_code{
        position: absolute;
        top: -123px;
        left: 270px;
       
      }
      .google_code{
      

        position: absolute;
        top: -189px;
        left: 270px;

      }
      .apple_code{
      

        position: absolute;
        top: -123px;
        left: 20px;

      }
    }
    .description{
      width: 487px;
    font-family: Helvetica;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 15px;
    letter-spacing: 1px;
    color: #ffffff;
    font-style: italic;
 
    
    }
  }
  .title-35{
      font-family: Helvetica-Bold;
	font-size: 35px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 40px;
	letter-spacing: 0px;
	color: #ffffff;

    }
    .hightItem{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 90px;

      .item{
        display: flex;
        flex-direction: row;
        width: 40%;
        margin-right: 10%;
        margin-bottom: 50px;
        .iconImage{
          height: 70px;
        }
        .name{
          font-family: Helvetica-Bold;
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 25px;
          letter-spacing: 0px;
          color: #ffffff;
        }
        .description{
          font-family: Helvetica;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 20px;
          letter-spacing: 0px;
          color: #fd79d0;
        }
      }
    }
  .upwardDes{
    width: 95%;
    
    .title-18{
      width: 100%;
      font-family: Helvetica;
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 28px;
	letter-spacing: 0px;
	color: #ffffff;
    }
    .uptickImage{
      width: 80%;
      margin: 0 10%;
      margin-top: 60px;
    }
  }





  &.mobile {
    display: flex !important;
    flex-direction: column !important;
    margin-top: 90px;
    .uptickImage{
      width: 100%;
      margin: 50px 0;
    }
    .hightItem{
     

      .item{
        display: flex;
        flex-direction: row;
        width: 100%;

        margin-bottom: 50px;
        
      
      }
    }
    
   .left{
    display: flex;
    flex-direction: column;
    align-items: center;
    .des{
      width: 75%;
      text-align: center;
    }
    .description{
      width: 75%;
      text-align: center;
    }
    .testflight{
      margin-top:8px;
      margin-left: 0px !important;
    }
    .download{
      display: flex !important;
    flex-direction: column !important;
    align-items: center;
    width: 100%;
    position: relative !important;
    }
   }
  }
}
</style>

